import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import { createSandbox } from 'vue-kindergarten'
import { userImportEventsService } from '@/services/ServerSentEvents/UserImportEventsService'
import Router from 'vue-router'
import upperFirst from 'lodash/upperFirst'
import AuthenticateModule from '@/store/modules/authenticationModule'
import RouteGoverness from '@/governesses/RouteGoverness'
import routePerimeter from '@/perimeters/routePerimeter'

const authenticateModule = getModule(AuthenticateModule)

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            // Needs to match redirect_uri in the oidcSettings
            path: '/signin-oidc-oplz',
            component: () => import('@/views/OIDC/OidcCallback.vue'),
            meta: {
                requiredAuth: false,
                hideHeader: true
            }
        },
        {
            // Needs to match post_logout_redirect_uri in the oidcSettings
            path: '/signout-oidc-oplz',
            component: () => import('@/views/OIDC/OidcSignoutCallback.vue'),
            meta: {
                requiredAuth: false,
                hideHeader: true
            }
        },
        {
            // Needs to match silent_redirect_uri in the oidcSettings
            path: '/silent-renew',
            component: () => import('@/views/OIDC/OidcSilentRenew.vue'),
            meta: {
                requiredAuth: false,
                hideHeader: true
            }
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            alias: '/',
            component: () => import('@/views/Dashboard.vue'),
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/accounts',
            name: 'accounts',
            component: () => import('@/views/Accounts/Accounts.vue'),
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/accounts/create',
            name: 'createAccount',
            component: () => import('@/views/Accounts/Create.vue'),
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/accounts/account/:id',
            name: 'account',
            component: () => import('@/views/Accounts/Account.vue'),
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/users',
            name: 'users',
            component: () => import('@/views/Users/Users.vue'),
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/holdings',
            name: 'holdings',
            component: () => import('@/views/Holdings/Holdings.vue'),
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/users/user/:id',
            name: 'user',
            component: () => import('@/views/Users/User.vue'),
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/general-settings',
            name: 'generalSettings',
            component: () => import('@/views/GeneralSettings/GeneralSettings.vue'),
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/import-log/:importId',
            name: 'importLog',
            component: () => import('@/views/ImportLogs/ImportLog.vue'),
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/abc-requests',
            name: 'abcRequests',
            component: () => import('@/views/AbcRequests/AbcRequests.vue'),
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/abc-requests/:id',
            name: 'abcRequest',
            component: () => import('@/views/AbcRequests/AbcRequest.vue'),
            meta: {
                requiredAuth: true
            }
        },
        {
            path: '/403',
            name: 'forbidden',
            component: () => import('@/views/ErrorPages/Forbidden.vue'),
            meta: {
                requiredAuth: false,
                hideHeader: true
            }
        }
    ]
})

router.beforeEach(async (to, from, next) => {
    const sandbox = createSandbox(authenticateModule.user, {
        perimeters: [routePerimeter],
        governess: new RouteGoverness(from, to, next)
    })
    const ruleName = `route${upperFirst(to.name)}`

    if (authenticateModule.isAuthenticated || !to?.meta?.requiredAuth) {
        return sandbox.guard(ruleName)
    }

    const authenticatedUser = await authenticateModule.authenticate()
    if (authenticatedUser) {
        userImportEventsService.initIfNeeded()
        return sandbox.guard(ruleName)
    }

    return next()
})

export default router
