import { UserManager, WebStorageStateStore, User } from 'oidc-client-ts'

class AuthService {
    private userManager: UserManager

    constructor() {
        const options = {
            userStore: new WebStorageStateStore({ store: window.localStorage }),
            authority: process.env.VUE_APP_IDENTITY_PROVIDER_AUTHORITY,
            client_id: process.env.VUE_APP_IDENTITY_PROVIDER_CLIENT_ID,
            redirect_uri: `${window.location.origin}${process.env.VUE_APP_IDENTITY_PROVIDER_REDIRECT_URI}`,
            post_logout_redirect_uri: `${window.location.origin}${process.env.VUE_APP_IDENTITY_PROVIDER_POST_LOGOUT_REDIRECT_URI}`,
            silent_redirect_uri: `${window.location.origin}${process.env.VUE_APP_IDENTITY_PROVIDER_SILENT_REFRESH_URI}`,
            response_type: 'code',
            scope: 'openid profile oplz oplzid.w:user-import oplz.r:user-import rw:user oplzid.r:user-import oplz-ai',
            filterProtocolClaims: true,
            automaticSilentRenew: true,
            monitorSession: true,
            includeIdTokenInSilentRenew: true,
            revokeTokensOnSignout: true
        }

        this.userManager = new UserManager(options)
    }

    public getUser(): Promise<User | null> {
        return this.userManager.getUser()
    }

    public login(): Promise<void> {
        return this.userManager.signinRedirect()
    }

    public logout(): Promise<void> {
        return this.userManager.signoutRedirect()
    }

    public signinRedirectCallback(url?: string): Promise<User> {
        return this.userManager.signinRedirectCallback(url)
    }

    public signinSilentCallback(): Promise<void> {
        return this.userManager.signinSilentCallback()
    }

    public signoutRedirectCallback() {
        return this.userManager.signoutRedirectCallback()
    }
}

const authService = new AuthService()

export default authService