import { Toast } from '@/models/infra/toast'
import {
    VuexModule, Mutation, Module
} from 'vuex-module-decorators'
import store from '../index'

@Module({
    dynamic: true,
    name: 'toastsModule',
    namespaced: true,
    store
})
export default class ToastsModule extends VuexModule {
    private lastId: number = 0
    private toastList: Toast[] = []

    public get toasts(): Toast[] {
        return this.toastList
    }

    @Mutation
    public addToast(toast: Omit<Toast, 'id'>): void {
        this.lastId += 1
        this.toastList.push({
            ...toast,
            id: this.lastId
        })
    }

    @Mutation
    public removeToast(id: number): void {
        const index = this.toastList.findIndex(toast => toast.id === id)
        if (index !== -1) { this.toastList.splice(index, 1) }
    }
}
