import { HeadGoverness } from 'vue-kindergarten'
import { RawLocation, Route } from 'vue-router'

export default class RouteGoverness extends HeadGoverness {
    next: (to?: RawLocation) => void
    from: Route
    to: Route

    constructor(from: Route, to: Route, next:  (to?: RawLocation) => void ) {
        super()
        this.next = next
        this.from = from
        this.to = to
    }

    guard(action: any): void {
        if(super.getRules(action)?.length)
            this.next(super.isAllowed(action) ? undefined : '/')
        this.next()
    }
}
