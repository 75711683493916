import { Perimeter } from 'vue-kindergarten'
import { UserClaim } from '@/models/entity/UserClaim'
import { IdentityServerUserRoles } from '../enums/IdentityServerUserRoles'

export default class BasePerimeter extends Perimeter {
    child: UserClaim = {} as UserClaim

    isDelegatedAccountAdmin(): boolean {
        return this.isInRole(IdentityServerUserRoles.DELEGATEDACCOUNTADMIN)
    }

    isAccountAdmin(): boolean {
        return this.isInRole(IdentityServerUserRoles.ACCOUNTADMIN)
    }

    isAccountImpersonation(): boolean {
        return this.isInRole(IdentityServerUserRoles.ACCOUNTIMPERSONATION)
    }
    
    isSupportAdmin(): boolean {
        return this.isInRole(IdentityServerUserRoles.SUPPORTADMIN)
    }

    private isInRole(role: IdentityServerUserRoles): boolean {
        if (Array.isArray(this.child?.role)) {
            return this.child?.role?.includes(role)
        }
        return this.child?.role === role
    }
}
