import { ToastType } from "@/models/infra/toast"
import AuthenticationModule from "@/store/modules/authenticationModule"
import ToastsModule from "@/store/modules/toastsModule"
import { EventSourcePolyfill } from "event-source-polyfill"
import { getModule } from "vuex-module-decorators"
import { BaseServerEventsHandler } from "./BaseServerSentEventHandler"
import { serverSentEventTrackService } from './ServerSentEventTrackService'

const toastModule = getModule(ToastsModule)

export interface UserImportEvents {
    ImportStarted: string;
    ImportSuccess: string;
    ImportFailed: string;
}

export class UserImportEventsService extends BaseServerEventsHandler<UserImportEvents> {
    private eventSource?: EventSourcePolyfill

    /** Only initializes listners if we have any import to track */
    public initIfNeeded(): void {
        try {
            if (serverSentEventTrackService.isTrackingAny()) {
                this.init()
            }
        } catch (e) {
            console.error('Error starting user import event listner', e)
        }
    }

    /** Initialize event listner */
    public init(): void {
        if (this.eventSource) return

        try {
            this.initEventSource()
        } catch (e) {
            console.error('Error starting user import event listner', e)
            this.eventSource = undefined
            return
        }

        this.initToastListeners()
    }

    public trackEvent(id: string): void {
        serverSentEventTrackService.addEventTracker(id)
        this.init()
    }

    /** Initialize EventSource to listen to server sent events */
    private initEventSource(): void {
        if (this.eventSource) return

        const authModule = getModule(AuthenticationModule)
        const token = authModule.token

        let baseUrl = process.env.VUE_APP_IDENTITY_PROVIDER_AUTHORITY
        if (!baseUrl.endsWith('/')) { baseUrl += '/' }

        const url = `${baseUrl}v1/user-import/user-import-events`

        if (!token) {
            console.warn("UserImportServerEvents: No token available")
            return
        }

        this.eventSource = new EventSourcePolyfill(url, { headers: { authorization: token } })
        this.eventSource.addEventListener('ImportStarted', e => this.emit('ImportStarted', (e as any).data))
        this.eventSource.addEventListener('ImportFailed', e => this.emit('ImportFailed', (e as any).data))
        this.eventSource.addEventListener('ImportSuccess', e => this.emit('ImportSuccess', (e as any).data))
    }

    private initToastListeners(): void {
        this.on('ImportStarted', importId => this.addToast('ImportStarted', importId))
        this.on('ImportFailed', importId => this.addToast('ImportFailed', importId))
        this.on('ImportSuccess', importId => this.addToast('ImportSuccess', importId))
    }

    private addToast(eventType: keyof UserImportEvents, importId?: string): void {
        if (!importId || (!serverSentEventTrackService.isTracking(importId) && eventType !== 'ImportStarted')) return

        let message = 'User import has started'
        let type: ToastType = 'generic'

        switch (eventType) {
            case 'ImportSuccess':
                message = 'User import has finished successfully'
                type = 'success'
                break
            case 'ImportFailed':
                message = 'User import has failed'
                type = 'error'
                break
            default:
                break
        }

        toastModule.addToast({
            message,
            type,
            linkName: 'View import log detail',
            linkTo: { name: 'importLog', params: { importId } }
        })
    }
}

const userImportEventsService = new UserImportEventsService()
export { userImportEventsService }
