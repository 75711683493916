<template>
  <div id="app">
    <recess-loader
      v-if="isAuthenticating || isFetching"
      :full-screen="true"
      overlay="white"
      color="primary"
    />
    <template v-if="isAuthenticated || !requireAuthentication">
      <header-component v-if="shouldShowHeader" />
      <router-view />
      <toast-list />
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import HeaderComponent from '@/components/HeaderComponent/HeaderComponent.vue'
import AuthenticateModule from '@/store/modules/authenticationModule'
import ToastList from '@/components/Toast/ToastList.vue'

const authenticateModule = getModule(AuthenticateModule)

@Component({
    components: {
        HeaderComponent,
        ToastList
    }
})
export default class App extends Vue {
    get isFetching(): boolean {
        return this.$store.state.isFetching
    }
    get isAuthenticating(): boolean {
        return authenticateModule.isLoading
    }

    get isAuthenticated(): boolean {
        return authenticateModule.isAuthenticated
    }

    get requireAuthentication(): boolean {
        return this.$route?.meta?.requiredAuth !== undefined ? this.$route.meta.requiredAuth : true
    }

    get shouldShowHeader(): boolean {
        return !this.$route?.meta?.hideHeader
    }
}
</script>
