import { SSEventTrack } from "@/models/infra/ServerSentEvents"

/** Service to track server side events started by current browser */
export class SSEventTrackService {
    private readonly expirationTime = 1000 * 60 * 60 * 24 // 1 day
    private readonly storageKey = '$SERVER_SENT_EVENTS'
    private readonly eventTrackers: SSEventTrack[]

    constructor() {
        const storage = localStorage.getItem(this.storageKey)
        if (typeof storage !== 'string') {
            this.eventTrackers = []
            return
        }

        try {
            const data = JSON.parse(storage) || []
            this.eventTrackers = Array.isArray(data) ? data : []
            this.removeExpiredTrackers()
        } catch {
            this.eventTrackers = []
        }
    }

    public addEventTracker(eventId: string): void {
        this.eventTrackers.push({
            eventId,
            timestamp: Date.now()
        })
        this.save()
    }

    public isTracking(eventId: string): boolean {
        this.save()
        return this.eventTrackers.some(e => e.eventId === eventId)
    }

    public removeExpiredTrackers(): void {
        this.eventTrackers.forEach(e => {
            if (Date.now() - e.timestamp > this.expirationTime) {
                this.eventTrackers.splice(this.eventTrackers.indexOf(e), 1)
            }
        })
    }

    public save(): void {
        this.removeExpiredTrackers()
        localStorage.setItem(this.storageKey, JSON.stringify(this.eventTrackers))
    }

    public isTrackingAny(): boolean {
        return this.eventTrackers.length > 0
    }
}

export const serverSentEventTrackService = new SSEventTrackService()
