/* eslint-disable */
import Vue from 'vue'
import VueKindergarten from 'vue-kindergarten'
import RecessUi from '@valtech/recess-ui'
import Vuelidate from 'vuelidate'
import App from './App.vue'
import router from './router'
import store from './store'

import './styles/main.scss'
import { UserClaim } from './models/entity/UserClaim'

Vue.config.productionTip = false

Vue.use(RecessUi)
Vue.use(Vuelidate)
Vue.use(VueKindergarten, {
    child: (store: { state: { authenticationModule: { user: UserClaim } } }) => store?.state?.authenticationModule?.user
})

new Vue({
    router,
    store: store,
    render: h => h(App)
}).$mount('#app')
