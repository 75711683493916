<template>
  <div class="toast-list">
    <recess-toast
      v-for="(t, i) in toasts"
      :key="i"
      :type="t.type"
      :is-static="true"
      :close-icon="true"
      :is-show="true"
      @close="closeToast(t.id)"
    >
      <template slot="toast-content">
        {{ t.message }}
        <template v-if="t.linkName && t.linkTo">
          <span class="u-min-w-5 d-inline-block"></span>
          <router-link
            v-if="t.linkName && t.linkTo"
            :to="t.linkTo"
          >
            {{ t.linkName }}
          </router-link>
        </template>
      </template>
    </recess-toast>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import ToastModule from '@/store/modules/toastsModule'
import { Toast } from '@/models/infra/toast'

const toastsModule = getModule(ToastModule)

@Component
export default class ToastList extends Vue {
    public get toasts(): Toast[] {
        return toastsModule.toasts
    }

    public closeToast(id: number): void {
        toastsModule.removeToast(id)
    }
}
</script>
