<template>
  <div class="c-header">
    <div class="c-header__wrapper d-flex align-items-center">
      <recess-layout-static :size="1700">
        <div class="d-flex justify-content-between align-items-center">
          <router-link
            :to="{name: 'dashboard'}"
            class="d-inline-flex"
          >
            <img
              src="@/assets/images/logos/logo.svg"
              class="c-logo"
              alt="logo"
            />
          </router-link>
          <recess-dropdown>
            <template #dropdown-user-icon>
              <img
                alt="profile-icon"
                src="@/assets/images/icons/icon-profile.png"
                class="c-menu-button__custom-icon"
              />
            </template>

            <template #dropdown-title>
              <h5 class="c-header__user-name">{{ emailAddress }}</h5>
            </template>
            <template #dropdown-content>
              <router-link
                to
                class="d-flex"
              >
                <img
                  alt="logout-icon"
                  src="@/assets/images/icons/icon-logout.svg"
                  class="mr-3"
                />
                <div @click.prevent="logout()">Logout</div>
              </router-link>
            </template>
          </recess-dropdown>
        </div>
      </recess-layout-static>
    </div>

    <recess-main-navigation>
      <recess-layout-static :size="1700">
        <div class="d-flex">
          <div class="c-recess-nav__list">
            <recess-navigation-item>
              <router-link
                :to="{name: 'dashboard'}"
                class="c-recess-nav__link"
              >
                Dashboard
              </router-link>
            </recess-navigation-item>

            <recess-navigation-item v-if="$isAllowed('routeGeneralSettings')">
              <router-link
                :to="{ name: 'generalSettings', query: { 'general-settings-tab': $route.query['general-settings-tab'] }}"
                class="c-recess-nav__link"
              >
                General Settings
              </router-link>
            </recess-navigation-item>

            <recess-navigation-item>
              <router-link
                :to="{name: 'users'}"
                class="c-recess-nav__link"
              >
                Users
              </router-link>
            </recess-navigation-item>

            <recess-navigation-item v-if="$isAllowed('routeHoldings')">
              <router-link
                :to="{name: 'holdings'}"
                class="c-recess-nav__link"
              >
                Holdings
              </router-link>
            </recess-navigation-item>

            <recess-navigation-item>
              <router-link
                :to="{name: 'accounts'}"
                class="c-recess-nav__link"
              >
                Accounts
              </router-link>
            </recess-navigation-item>

            <recess-navigation-item>
              <router-link
                :to="{name: 'abcRequests'}"
                class="c-recess-nav__link"
              >
                Abc Requests
              </router-link>
            </recess-navigation-item>
          </div>
        </div>
      </recess-layout-static>
    </recess-main-navigation>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import AuthenticateModule from '@/store/modules/authenticationModule'
import routePerimeter from '@/perimeters/routePerimeter'

const authenticateModule = getModule(AuthenticateModule)

@Component({
    perimeters: [ routePerimeter ]
})
export default class HeaderComponent extends Vue {
    get emailAddress(): string | null {
        return authenticateModule.userEmailAddress
    }

    logout(): void {
        authenticateModule.logout()
    }
}
</script>

