import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { User } from 'oidc-client-ts'
import authService from '@/services/AuthService'
import { IdentityServerUserRoles } from '@/enums/IdentityServerUserRoles'
import { UserClaim } from '@/models/entity/UserClaim'
import store from '../index'

function isAuthorizedRole(roles?: string[]): boolean {
    if (!roles?.length) return false
    return roles.includes(IdentityServerUserRoles.ACCOUNTADMIN) ||
    roles.includes(IdentityServerUserRoles.DELEGATEDACCOUNTADMIN) ||
    roles.includes(IdentityServerUserRoles.SUPPORTADMIN)
}

@Module({ dynamic: true, name: 'authenticationModule', namespaced: true, store })
export default class AuthenticationModule extends VuexModule {
    token: string | null = null
    isAuthenticated: boolean = false
    isLoading: boolean = false
    user: UserClaim | null = null

    get getToken(): string | null {
        return this.token
    }

    get userEmailAddress(): string | null {
        return this.user && this.user.email_address
    }

    get userRoles(): string[] | null {
        return this.user && this.user.role
    }

    @Mutation
    resetState(): void {
        this.token = null
        this.isAuthenticated = false
        this.user = null
    }

    @Mutation
    setToken(token: string): void {
        this.token = token
    }

    @Mutation
    setUser(user: UserClaim): void {
        this.user = user
    }

    @Mutation
    setIsAuthenticated(isAuthenticated: boolean): void {
        this.isAuthenticated = isAuthenticated
    }

    @Mutation
    setIsLoading(isLoading: boolean): void {
        this.isLoading = isLoading
    }

    @Action
    async authenticate(): Promise<User | null> {
        try {
            this.context.commit('setIsLoading', true)
            const authenticatedUser = await  authService.getUser()
            if(!authenticatedUser || authenticatedUser.expired) {
                await this.login()
                return null
            }

            if (!isAuthorizedRole(authenticatedUser.profile?.role as string[] | undefined)) {
                window.location.href = `/403`
                this.context.commit('setIsLoading', false)
                return null
            }

            this.context.commit('setToken', `Bearer ${authenticatedUser.access_token}`)
            this.context.commit('setIsAuthenticated', true)
            this.context.commit('setUser', authenticatedUser.profile)
            this.context.commit('setIsLoading', false)
            return authenticatedUser
        } catch (error) {
            throw new Error("Failed to authenticate", { cause: error})
        } 
    }

    @Action
    async login(): Promise<void> {
        try {
            this.context.commit('setIsLoading', true)
            await authService.login()
        } catch (error) {
            throw new Error("Failed to login", { cause: error })
        }
    }

    @Action
    async logout(): Promise<void> {
        try {
            this.context.commit('setIsLoading', true)
            await authService.logout()
            this.context.commit('resetState')
        } catch (error) {
            throw new Error("Failed to logout", { cause: error })
        }
    }

    @Action
    async checkUserExpired(): Promise<boolean> {
        try {
            const authenticatedUser = await authService.getUser()
            return !authenticatedUser || authenticatedUser.expired !== false
        } catch (error) {
            throw new Error("Failed to check if user is expired", { cause: error })
        }
    }
}
