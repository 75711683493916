import { SSEventCallback, SSEventCallbackMap } from '@/models/infra/ServerSentEvents'

export abstract class BaseServerEventsHandler<T extends object> {
    private handlers: SSEventCallbackMap<T> = {}

    public abstract init(): void;

    /** Add event listner */
    public on<k extends Extract<keyof T, string>>(type: k, handler: SSEventCallback<T, k>): void {
        const eventHandlers = (this.handlers[type] || []) as SSEventCallback<T, k>[]
        eventHandlers.push(handler)
        this.handlers[type] = eventHandlers
    }

    /** Remove event listner */
    public off<k extends Extract<keyof T, string>>(type: k, handler: SSEventCallback<T, k>): void {
        const eventHandlers = (this.handlers[type] || []) as SSEventCallback<T, k>[]
        const index = eventHandlers.indexOf(handler)
        if (index > -1) {
            eventHandlers.splice(index, 1)
        }
    }

    /** add a catch all event listner */
    public onAll(handler: SSEventCallback<T, any>): void {
        this.on('*' as any, handler as any)
    }

    /** remove a catch all event listner */
    public offAll(handler: SSEventCallback<T, any>): void {
        this.off('*' as any, handler)
    }

    /** emit an event */
    public emit<k extends Extract<keyof T, string>>(type: k, value?: T[k]): void {
        const handler = this.handlers[type]
        if (handler) {
            handler.forEach(h => {
                try {  h(value) }
                catch (e) { console.error(e) }
            })
        }
        this.emitAll(value)
    }

    private emitAll(value?: any): void {
        const handler = this.handlers['*'] as SSEventCallback<T, any>[]
        if (handler) {
            handler.forEach(h => {
                try {  h(value) }
                catch (e) { console.error(e) }
            })
        }
    }
}
