// @ts-nocheck
import BasePerimeter from './BasePerimeter'

export default new BasePerimeter({
    purpose: 'route',

    can: {
        routeCreateAccount(): boolean {
            return this.isAccountAdmin() || this.isDelegatedAccountAdmin()
        },
        routeGeneralSettings(): boolean {
            return this.isAccountAdmin() || this.isSupportAdmin()
        },
        routeHoldings(): boolean {
            return this.isAccountAdmin()
        }
    }
})
